<template>
  <div class="home">
    <div class="tab-type">
      <div style="padding: 10px">
        <a-breadcrumb :style="{ margin: '16px 0' }">
          <a-breadcrumb-item>主页</a-breadcrumb-item>

          <a-breadcrumb-item><a href="">项目</a></a-breadcrumb-item>
        </a-breadcrumb>
      </div>
      <a-card>
        <a-tabs type="card">
          <template #rightExtra>
            <a-button type="primary" @click="openModel">
              <template #icon>
                <CloudUploadOutlined />
              </template>
              新建项目
            </a-button>
          </template>
          <a-tab-pane key="1" tab="项目">
            <div class="card-row">
              <div class="search-box">
                <div class="table-top-layout"></div>
              </div>
              <div class="up-box"></div>
            </div>
            <tableComponent
              :columns="columns"
              :dataSource="dataSource"
              :loading="stateData.loading"
            >
              <template v-slot:tableBody="{ column, text, record }">
                <template v-if="column.dataIndex === 'action'">
                  <span>
                    <router-link
                      :to="{ path: '/project_ch', query: { id: record.id } }"
                      >查看素材</router-link
                    >
                    <a-divider type="vertical" />

                    <a-button size="small" @click="edit(record)" type="primary"
                      >编辑</a-button
                    >
                    <a-divider type="vertical" />
                    <a-popconfirm
                      title="确定删除?"
                      ok-text="是"
                      cancel-text="否"
                      @confirm="del(record)"
                    >
                      <a-button size="small">删除</a-button>
                    </a-popconfirm>
                  </span>
                </template>
                <template v-else-if="column.dataIndex === 'tagType'">
                  <span>
                    <a-tag
                      v-for="tag in record.tagType"
                      :key="tag"
                      :color="
                        tag === 'loser'
                          ? 'volcano'
                          : tag.length > 5
                          ? 'geekblue'
                          : 'green'
                      "
                    >
                      {{ tag }}
                    </a-tag>
                  </span>
                </template>

                <template v-else-if="column.dataIndex === 'video'">
                  <span>
                    <img
                      :src="text"
                      style="max-width: 200px; max-height: 80px"
                      alt=""
                    />
                  </span>
                </template>
                <template v-else-if="column.dataIndex === 'permission'">
                  <span> 公共 </span>
                  <!-- <span v-if="text == 1"> 公共 </span> -->
                </template>
              </template>
            </tableComponent>
          </a-tab-pane>
        </a-tabs>
      </a-card>
    </div>
    <addModel ref="refaddModel" @handleGetList="getItemList"></addModel>
    <editModel ref="refEditModel" @handleGetList="getItemList"></editModel>
  </div>
</template>

<script>
// @ is an alias to /src
import tableComponent from "@/components/table/tableComponent.vue";
import { defineComponent, reactive, ref, toRaw, getCurrentInstance } from "vue";
import { useStore } from "vuex";

import { PoweroffOutlined, CloudUploadOutlined } from "@ant-design/icons-vue";

import { columns } from "./columns.js";

import { getProjectList, delProject } from "@/api/project";
import { load } from "@/utils/loading/load.js";
import addModel from "./modal/addProject.vue";
import editModel from "./modal/editProject.vue";
export default defineComponent({
  name: "Home",
  components: {
    tableComponent,
    PoweroffOutlined,
    CloudUploadOutlined,
    addModel,
    editModel,
  },

  setup(props, { emit }) {
    const stateData = reactive({
      loading: false,
    });
    const dataSource = ref([]);
    const refaddModel = ref(null);
    const refEditModel = ref(null);
    const { proxy } = getCurrentInstance();
    /**
     * 获取项目列表
     */
    const handleProjectList = async () => {
      // load.show("加载中");
      stateData.loading = true;
      let res = await getProjectList();
      stateData.loading = false;
      dataSource.value = res.data;
      // setTimeout(() => {
      //   load.hide();
      // }, 2000);
    };
    const openModel = () => {
      refaddModel.value.handleOpenModel(true);
    };
    const edit = (record) => {
      refEditModel.value.handleOpenModel(true, record);
    };
    const del = (record) => {
      delProject({ id: record.id }).then((res) => {
        if (res.code == "ok") {
          proxy.$Message.success(res.msg);
          handleProjectList();
        } else {
          proxy.$Message.error(res.msg);
        }
      });
    };
    const handleCreate = () => {
      handleProjectList();
    };
    handleCreate();
    const getItemList = () => {
      handleProjectList();
    };
    return {
      //ref
      refaddModel,
      refEditModel,
      //data
      stateData,
      columns,
      dataSource,
      //method
      openModel,
      getItemList,
      edit,
      del,
    };
  },
});
</script>
<style scoped lang="less">
.card-row {
  padding: 10px;

  .search-box {
    display: inline-block;
  }

  .up-box {
    display: inline-block;
    float: right;
  }
}

.tab-type {
}
</style>